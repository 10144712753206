import React from 'react';
import './App.css'; // Ensure Tailwind CSS is imported
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import ContactSection from './components/Contact';
import Footer from './components/Footer';
import { useState } from 'react';
import FullScreenImage from './components/workInProgress';
import AllAthletes from './components/allAthletes';
import MainComponent from './components/main';

function App() {
  const [modalOpen, setModalOpen] = useState(false);
  const RedirectToStripe = () => {
    window.location.replace("https://buy.stripe.com/9AQ5my66xeca3S03cf");
    return null;
  };
  
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route exact path="/hidden" element={<FullScreenImage />} />
          <Route path="/" element={<MainComponent />} />
          <Route path="/athletes" element={<><AllAthletes /><ContactSection /><Footer /></>} />
          <Route path="/cuwbb/give" element={<RedirectToStripe />} />
          <Route path="/cuwbb" element={<RedirectToStripe />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
